import React, { Component } from "react";
import { Container } from "reactstrap";
import { NavMenu } from "./NavMenu";
import * as constants from "../constants";

export const Layout = ({ children, themeString, themeHandler }) => {
  return (
    <div>
      {constants.isLocal && (
        <NavMenu themeString={themeString} themeHandler={themeHandler} />
      )}
      <Container style={{ paddingTop: "0px" }} fluid id="main">
        {children}
      </Container>
    </div>
  );
};
