import "./App.css";
import { Alert, Provider } from "@fluentui/react-northstar";
import { Fragment } from "react";
import Dashboards from "./components/pages/Dashboard";
import Configuration from "./components/pages/Configuration/configurations";
import SharedDashboardComponent from "./components/LiveUrlShared/shareddashboard";
import SharedCardComponent from "./components/LiveUrlShared/sharedcard";

import AccessDenied from "./components/Auth/AccessDenied";
import NotProvisioned from "./components/Auth/NotProvisioned";
import ProvisioningInProgress from "./components/Auth/ProvisioningInProgress";

import SignInStart from "./components/Auth/SignInStart";
import SignInEnd from "./components/Auth/SignInEnd";
import SilentTokenStart from "./components/Auth/SilentTokenStart";

import { Layout } from "./components/Layout";
import NotTeams from "./components/pages/Home/NotTeams";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import AuthenticatedRoute from "./components/RouteHelper";
import AppOverview from './components/Shared/uicomponents/AppOverview';
import WindowProvider from "./components/Shared/Context/ContextProvider";
import WindowContext from "./components/Shared/Context/Context";
// import A365Intro from "./components/pages/a365Intro";
// import ReactJoyride, { STATUS } from "react-joyride";
import { useState } from "react";
import ProvisioningState from "./components/Auth/ProvisioningState";


const ProtectedRouteContainer = (props) => {
  return (
    <Layout themeString={props.themeString} themeHandler={props.setTheme}>
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboards" />} />
        <AuthenticatedRoute path="/dashboards" component={Dashboards} />
        <AuthenticatedRoute path="/configuration" component={Configuration} />
        <Route path='/appoverview' component={AppOverview} />
      </Switch>
    </Layout>
  );
};
const App = () => {
  var sdDoesntContains = window.location.href.toLowerCase().indexOf("/sd/") == -1;
  var scDoesntContains = window.location.href.toLowerCase().indexOf("/sc/") == -1;

  // const [{ run, steps, stepIndex }, setState] = useState({
  //   run: true,
  //   steps: [
  //     {
  //       content: <h2>Lets get started</h2>,
  //       locale: { skip: <strong>Skip</strong> },
  //       placement: "center",
  //       target: "body",
  //       stepIndex: 0,
  //     },
  //     {
  //       content: <h2>Lets get started</h2>,
  //       placement: "bottom",
  //       target: "#a36555",
  //       title: "first step",
  //       spotlightClicks: true,
  //       disableBeacon: true,
  //       disableOverlayClose: true,
  //       hideCloseButton: true,
  //       hideFooter: true,
  //       stepIndex: 1,
  //       style: {
  //         options: {
  //           color: "green"
  //         }
  //       }
  //     },
  //     {
  //       content: <h2>Lets get started</h2>,
  //       placement: "bottom",
  //       target: "#a36555",
  //       title: "first step",
  //       stepIndex: 2,
  //     },
  //     {
  //       content: <h2>Lets get started</h2>,
  //       placement: "bottom",
  //       target: "#a36555",
  //       title: "first step",
  //       stepIndex: 3,
  //     }
  //   ]
  // })

  // const handleClicks = () => {
  //   if (steps === 1) {
  //     setState(
  //       steps++)
  //   }
  // }

  return (
    <WindowProvider>
      <WindowContext.Consumer>
        {(context) =>
          <Provider theme={context.teams.theme}>
            {/* <A365Intro /> */}
            {/* <ReactJoyride
              continuous
              callback={() => { handleClicks() }}
              run={run}
              steps={steps}
              hideCloseButton
              scrollToFirstStep
              showSkipButton
              stepIndex={stepIndex}
              showProgress
            /> */}
            <Switch>
              <Route exact path="/sd/" component={SharedDashboardComponent} />
              <Route exact path="/sc/" component={SharedCardComponent} />
              {sdDoesntContains && scDoesntContains &&
                <Route>
                  {
                    context.teams.inTeams || context.constants.isLocal ? (
                      <Fragment>

                        <Switch>
                          <Route exact path="/provision" component={ProvisioningState} />
                          <Route
                            exact
                            path="/accessdenied"
                            component={AccessDenied}
                          />
                          <Route
                            exact
                            path="/provisioning"
                            component={ProvisioningInProgress}
                          />
                          <Route exact path="/signinstart" component={SignInStart} />
                          <Route exact path="/notprovisioned" component={NotProvisioned} />
                          <Route
                            exact
                            path="/silenttokenstart"
                            component={SilentTokenStart}
                          />
                          <Route exact path="/signinend" component={SignInEnd} />
                          <Route>
                            <ProtectedRouteContainer
                              setTheme={context.teams.setTheme}
                              themeString={context.teams.themeString}
                            />
                          </Route>
                        </Switch>
                      </Fragment>
                    ) : (
                      <NotTeams />
                    )
                  }
                </Route>
              }
            </Switch>
          </Provider>
        }
      </WindowContext.Consumer>
    </WindowProvider>
  );
};

export default App;
