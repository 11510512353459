import React from "react";
import { List, Divider } from "@fluentui/react-northstar";
import { HorizontalRuleIcon, CallDialpadIcon } from "@fluentui/react-icons-northstar";

import "./dashboard.scss";

const CardCategories = (props) => {
  const items = [
    {
      key: "isAll",
      media: <HorizontalRuleIcon size="smaller" />,
      header: "All",
    },
    {
      key: "isProductivity",
      media: <i className={"analytics-productivity"} />,
      header: "People Engagement",
    },
    {
      key: "isCustomerExperience",
      media: <i className={"analytics-customerExperience"} />,
      header: "One-to-One Engagement",
    },
    {
      key: "isWellness",
      media: <i className={"analytics-wellbeing"} />,
      header: "Wellbeing",
    },
    {
      key: "isTeamsCalling",
      media: <i className={"analytics-headphones"} />,
      header: "External Engagement",
    },
    {
      key: "1",
      media: <i className={"analytics-summary"} />,
      header: "Summary",
    },
    {
      key: "2",
      media: <i className={"analytics-activity2"} />,
      header: "Activity",
    },
    {
      key: "3",
      media: <i className={"analytics-trends"} />,
      header: "Trends",
    },
    {
      key: "4",
      media: <i className={"analytics-comparison2"} />,
      header: "Comparison",
      },
      // {
      //     key: "5",
      //     media: <i className={"analytics-insights"} />,
      //     header: "Insights",
      // },
      {
        key: "2",
        media: <i className={"analytics-meetings"} />,
        header: "Meetings",
      },
      {
        key: "1",
        media: <i className={"analytics-calls"} />,
        header: "Calls",
      },
      {
        key: "3",
        media: <i className={"analytics-messages"} />,
        header: "Messages",
      },
      {
        key: "4",
        media: <i className={"analytics-collaboration"} />,
        header: "Collaboration",
        },
        {
          key: "5",
          media: <i className={"analytics-teamsChannels"} />,
          header: "Teams and Channels",
          },
  ];

  return (
    <>
      <Divider important content="Engagement" size={2} />
      <List
        className="categorylist"
        selectable
        selectedIndex={props.selectedCategory}
        onSelectedIndexChange={(e, newProps) => {
          props.handleCategoryChange(
            newProps.selectedIndex,
            newProps.items[newProps.selectedIndex].key,
            1
          );
        }}
        items={items.slice(0, 5)}
        style={{ width: "100%" }}
      />
      <Divider important content="Types" size={2} />
      <List
        className="categorylist"
        selectable
        selectedIndex={props.selectedCategory - 5}
        onSelectedIndexChange={(e, newProps) => {
          props.handleCategoryChange(
            newProps.selectedIndex + 5,
            "categoryTypeID",
            Number(newProps.items[newProps.selectedIndex].key)
          );
        }}
        items={items.slice(5, 9)}
        style={{ width: "100%" }}
      />
     <Divider important content="Activity" size={2} />
      <List
        className="categorylist"
        selectable
        selectedIndex={props.selectedCategory - 1}
        onSelectedIndexChange={(e, newProps) => {
          props.handleCategoryChange(
            newProps.selectedIndex + 9,
            "collaborationTypeID",
            Number(newProps.items[newProps.selectedIndex].key)
          );
        }}
        items={items.slice(9, 15)}
        style={{ width: "100%" }}
      />
    </>
    
  );
};

export default CardCategories;
