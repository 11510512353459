import React from 'react';
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import './auth.scss';
import './notprovisioned.scss';
import { useHistory } from "react-router-dom";
import Impersonation from '../pages/Configuration/Impersonation';
import '../pages/Dashboard/dashboards';
import {
    Checkbox,
    toggle
} from "@fluentui/react-northstar";

const AccessDenied = () => {
    const { state } = useLocation();
    const { from } = state || { from: { pathname: "/" } };
    const history = useHistory();
    const [impersonateMode, setImpersonateMode] = useState(false);


    const setDemo = (isChecked) => {
        global.localStorage.setItem("demoGlobal", "true");
        history.push(from.pathname);  
    }

    const handleImpersonateMode = (checked) => {
        //global.localStorage.setItem("demoMode", "true")
        if (global.localStorage) {
            Impersonation.RemoveImpersonation();
        }
        history.push(from.pathname);  
    }

    useEffect(() => {
        if (window.localStorage.getItem("impersonationMode") == "true") {
            setImpersonateMode(true);
        }
    }, []);

    //(A) FETCH "HTML"
    fetch("https://www.analytics-365.com/insight-dashboard")

        //(B) RETURN THE RESULT AS TEXT
        .then((result) => {
            if (result.status !== 200) { throw new Error("Bad Server Response"); }
            return result.text();
        })

        //(C) PUT LOADED CONTENT INTO <DIV>
        .then((content) => {
            //debugger
            content = content.replaceAll(`src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%200%200'%3E%3C/svg%3E"`, '');
            content = content.replaceAll('data-lazy-src', 'src');
            document.getElementById("demoShow").innerHTML = content;
            document.getElementById("footer").remove()
            document.getElementById("main").style.height = "95vh";
            document.getElementById("main").style.padding = 0;

        })

        //(D) HANDLE ERRORS - OPTIONAL
        .catch((error) => { console.log(error); });


    return (
        <>

            {/* <iframe id="myframe" src="https://www.analytics-365.com/insight-dashboard/" frameborder="0" style={{ height: "99.4vh", width: "100%" }}>

            </iframe> */}
            <div class="row px-3 pt-1 pb-2 bg-secondary">
                
                <div class="col">
                    <p class="col fs-12 text-white text-center mb-0">Experience the app with sample data. <a className='text-light' onClick={(event, isChecked) => {
                        setDemo(isChecked);
                    }} style={{ cursor: "pointer" }}><em><u>Demo Mode</u></em></a></p>
                </div>

                 {impersonateMode &&
                        <div class="col-auto fs-12 text-black mb-0 bg-light p-1 mr-0">
                            <div class="demoMode">
                                <span class="ui-text text-center" dir="auto">Remote Assist : </span>
                                <Checkbox
                                    toggle
                                    checked={impersonateMode}
                                    onClick={(event, isChecked) => {
                                        handleImpersonateMode(isChecked.checked);
                                    }}
                                />
                        </div>
                    </div>
                 }
                
            </div>
            <div id="demoShow">
            </div>

        </>
    );
}

export default AccessDenied;