import { useEffect, useState } from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import AuthHelper from "../services/auth-helper";
import TenantApiService from "../services/TenantApiService";
import { Loader } from "@fluentui/react-northstar";
import { SignUpProvider } from "./Shared/Context/SignUpContext";
import Impersonation from "../components/pages/Configuration/Impersonation";

// const renderRedirect = () => {
//   storeCurrentPath();

//   return <Redirect to={{ pathname: "/login", state: { from: location } }} />;
// };

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
    const _location = useLocation();
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [isAnalytics, setIsAnalytics] = useState(false);
    const [isAdmin, setIsAdmin] = useState(false);
    const [tenantStatus, setTenantStatus] = useState(100);
    const [subscriptionStatus, setSubscriptionStatus] = useState(-1);
    const [customerStatus, setCustomerStatus] = useState(0);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isFirstLogin, setIsFirstLogin] = useState(false);
    const [demoModeVisible, setDemoModeVisible] = useState(false);
    const [globalDemoModeOverride, setGlobalDemoModeOverride] = useState(false);
    const [isReporting, setIsReporting] = useState(0);
    const [isUpdatesSeen, setIsUpdatesSeen] = useState(1);
    const [demoDashboardJourneyState, setDemoDashboardJourneyState] = useState(2);

    const authSuccessCallback = (token) => {
        if (token) {
            setIsAuthenticated(true);
        }
        TenantApiService.GetAccountPermission(token).then((response) => {
            if (response) {
                setTenantStatus(response.tenantStatus);
                setSubscriptionStatus(response.subscriptionStatus);
                setCustomerStatus(response.customerStatus);
                if (response.tenantStatus >= 4) {
                    setIsAdmin((response.administrativeAccess === 1 || response.administrativeAccess === 2 
                        || (response.administrativeAccess === 0 && (response.reportingAccess === 1 ||  response.reportingAccessInherited === 1))
                        || response.administrativeAccessInherited === 1 || response.administrativeAccessInherited === 2 
                        || (response.administrativeAccessInherited === 0 && (response.reportingAccess === 1 ||  response.reportingAccessInherited === 1))));
                    setIsAnalytics((response.reportingAccess === 1 || response.reportingAccessInherited === 1));
                    setIsDeleted(response.isDeleted);
                    setDemoModeVisible(response.demoModeVisible);
                    setIsFirstLogin(response.isFirstTimeLogin);
                    setIsReporting(response.reportingAccess);
                    setIsUpdatesSeen(response.isUpdatesSeen);
                    setDemoDashboardJourneyState(response.demoDashboardJourneyState);
                }
            }


            let demo = global.localStorage.getItem("demoGlobal");
            if (demo === "true") {
                setGlobalDemoModeOverride(true);
            }
            else {
                setGlobalDemoModeOverride(false);
            }
            setIsLoaded(true);
        });
    };

    useEffect(() => {
        let demo = global.localStorage.getItem("demoGlobal");
        if (demo === "true" && _location.pathname.includes("dashboards")) {
            Impersonation.RemoveImpersonation();
        }
        global.localStorage.removeItem("isCustomerNotRegistered");
        AuthHelper.getAccessToken(authSuccessCallback); 
    }, []);

    return (
        <>
            <SignUpProvider value={tenantStatus}>
                {isLoaded ?
                    (
                        <Route
                            {...rest}
                            render={(props) =>
                                isAuthenticated ?
                                    (customerStatus === 1 && !isDeleted && (tenantStatus === 4 || tenantStatus === 5) && [0, 1, 3, 6, 10].includes(subscriptionStatus) 
                                    && ((!isFirstLogin && isUpdatesSeen === 1 && isAnalytics && props.location.pathname.includes("dashboards")) || ((isAdmin) && props.location.pathname.includes("configuration"))) 
                                    || globalDemoModeOverride)?
                                    <Component demoModeVisible={demoModeVisible} {...props} /> :
                                    <Redirect to={{ pathname: "/provision", state: { tenantStatus: tenantStatus,subscriptionStatus: subscriptionStatus ,customerStatus : customerStatus , isFirstLogin:isFirstLogin , isDeleted: isDeleted, isReporting:isReporting, isUpdatesSeen:isUpdatesSeen, demoDashboardJourneyState: demoDashboardJourneyState} }} />
                                    :                                     
                                    <Redirect to={{ pathname: "/login", state: { from: _location } }} />
                            }
                        ></Route>
                    ) : <Loader />
                }
            </SignUpProvider>
        </>
    );
};

export default AuthenticatedRoute;
