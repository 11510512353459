import React, { useEffect, useState } from "react";
import FiltersApiService from "../../services/FiltersApiService";
import {
    Card,
    CardHeader,
    CardBody,
    Flex,
    Button,
    Text,
    Loader,
    TrashCanIcon,
    SettingsAudioIcon,
    DownloadIcon,
    Dialog,
    Segment,
    ExclamationTriangleIcon,
    Avatar
} from "@fluentui/react-northstar";
import { ExpandIcon, CollapseIcon, InfoIcon, TranscriptIcon } from '@fluentui/react-icons-northstar';
import {
    BarChart,
    LineChart,
    BasicSummary,
    UserComparisonView,
    ComparisonView,
    DonutChart,
    GroupedGrid,
    MeetingHabitsIndicator,
    MeetingSummaryView,
    PieChart,
    ReactionsView,
    StackedBarChart,
    StandardGrid,
    TeamSummaryView,
    TopUsersGrid,
    MeetingParticipation,
    UserSummaryView,
    UserAnalytics,
    CallQueueSummaryView,
    ExpandableRowGrid,
    ColumnChart,
    TeamEngagementView,
    StackedBarChartAndGroupedGrid,
    DirectReportOverview,
    OnetoOneOverview,
    OrganizationalChart,
    ManagementInsights,
    GridFilteredByInsight
} from "../viewTypes/index";
import MockData from "../viewTypes/mockData";
import MaximizableView from "../Shared/uicomponents/MaximizableView";
import useFullscreenStatus from "../Shared/CustomHooks/useFullScreenStatus";
import '../cards/Card/card.scss';
import "./sharedDashboard.scss";
import { cardFilters } from "../filters/card/CardFiltersData/CardFiltersData";
import { Calendar } from "@material-ui/pickers";
import * as moment from "moment";
import ReactDOMServer from "react-dom/server";
import jsPDF from "jspdf";
import { set } from "lodash";
import html2canvas from 'html2canvas';


Date.prototype.AddDays = function (days) {
    return (new Date(this.setDate(this.getDate() + days)));
}
Date.prototype.GetFirstDayOfWeek = function () {
    return (new Date(this.setDate(this.getDate() - this.getDay() + (this.getDay() == 0 ? -6 : 1))));
}
Date.prototype.GetFirstDayOfMonth = function () {
    return (new Date(this.setDate(1)));
}


const SharedCardWidget = (props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [data, setData] = useState({});
    const [operating, setOperating] = useState(false);
    const [noData, setNoData] = useState(false);
    const [isInfoView, setInfoView] = useState(false);
    const [isInfoViewAvailable, setInfoViewAvailable] = useState(false);
    const [appliedFilters, setAppliedFilters] = useState([]);
    const [cardLayout, setCardLayout] = useState({ w: 1, h: 1 });
    const [isDemo, setIsDemo] = useState(false);

    let cardTimer;
    let mockData = MockData[props.card.widgetID].data;

    const maximizableElement = React.useRef(null);
    let isFullscreen, setIsFullscreen;
    let errorMessage;

    try {
        [isFullscreen, setIsFullscreen] = useFullscreenStatus(maximizableElement);
    } catch (e) {
        errorMessage = "Fullscreen not supported";
        isFullscreen = false;
        setIsFullscreen = undefined;
    }

    useEffect(() => {
        setIsDemo(props.isDemo);
    }, [props.demo]);

    function prepareInfoData(_appliedFilters) {
        try {
            let widget_filterIds = props.allCardsData === undefined ? cardFilters[props.card.cardFilterID] : props.allCardsData.reduce((acc, curr) => (acc[curr.widgetID] = cardFilters[curr.cardFilterID], acc), {});
            let applied = [];
            if (_appliedFilters) {
                let _fromDate = null;
                let toDate = _appliedFilters.selectedEndDate === null ? new Date() : new Date(_appliedFilters.selectedEndDate);//new Date(props.selectedDate);
                _fromDate = _appliedFilters.selectedEndDate === null ? new Date() : new Date(_appliedFilters.selectedEndDate);
                switch (_appliedFilters.runPeriod) {
                    case 1:   //this week        
                        _fromDate = _fromDate.GetFirstDayOfWeek();
                        break;
                    case 2: //this month
                        _fromDate = _fromDate.GetFirstDayOfMonth();
                        break;
                    case 3: //Last 7 Days
                        _fromDate = _fromDate.AddDays(-7);
                        break;
                    case 4: //Last 30 Days
                        _fromDate = _fromDate.AddDays(-30);
                        break;
                    case 5://Custom Date
                        _fromDate = new Date(_appliedFilters.selectedStartDate);
                        break;
                    case 6://Yesterday
                        _fromDate = _fromDate.AddDays(-1);
                        break;
                    case 7://Last Week
                        _fromDate = _fromDate.GetFirstDayOfWeek();
                        _fromDate = _fromDate.AddDays(-7);
                        toDate = _fromDate.AddDays(6);
                        _fromDate = _fromDate.AddDays(-6);
                        break;
                    case 8: //Last Month
                        _fromDate = _fromDate.GetFirstDayOfMonth();
                        toDate = _fromDate.AddDays(-1);
                        _fromDate = _fromDate.GetFirstDayOfMonth();
                        break;
                }
                if (_fromDate) {
                    applied.push({
                        header: 'Period',
                        value: new Date(_fromDate).toDateString().substr(4) + ' - ' + new Date(toDate).toDateString().substr(4)// _fromDate.toDateString().substr(4)
                    });
                }
                else {
                    applied.push({ header: 'Period', value: new Date(_appliedFilters.selectedStartDate).toDateString().substr(4) });
                }

                switch (_appliedFilters.timePeriod) {
                    case 0:
                        applied.push({ header: 'Time', value: 'Working Hours' });
                        break;
                    case 1:
                        applied.push({ header: 'Time', value: 'Outside Working Hours' });
                        break;
                    case 2:
                        applied.push({ header: 'Time', value: _appliedFilters.selectedStartTime + ' - ' + _appliedFilters.selectedEndTime });
                        break;
                }
                if (!(_appliedFilters.userFilter == null || _appliedFilters.userFilter == "")) {
                    _appliedFilters.userFilter = _appliedFilters.userFilter.split(',').map(Number);
                    let _valueArray = props.userFiltersData.userFilter.items.filter(u => _appliedFilters.userFilter.includes(u.value));
                    let _count = _valueArray.length;
                    _valueArray = _valueArray.slice(0, 5);
                    if ((_count - 5) > 0)
                        _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                    applied.push({
                        isHtml: true,
                        header: 'People',
                        value: _valueArray
                    });
                }
                else if (!(_appliedFilters.teamFilter == null || _appliedFilters.teamFilter == "")) {
                    _appliedFilters.teamFilter = _appliedFilters.teamFilter.split(',').map(Number);
                    let _valueArray = props.userFiltersData.teamFilter.items.filter(u => _appliedFilters.teamFilter.includes(u.value));
                    let _count = _valueArray.length;
                    _valueArray = _valueArray.slice(0, 5);
                    if ((_count - 5) > 0)
                        _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                    applied.push({
                        isHtml: true,
                        header: 'Teams',
                        value: _valueArray
                    });
                }
                else if (!(_appliedFilters.groupFilter == null || _appliedFilters.groupFilter == "")) {
                    _appliedFilters.groupFilter = _appliedFilters.groupFilter.split(',').map(Number);
                    let _valueArray = props.userFiltersData.groupFilter.items.filter(u => _appliedFilters.groupFilter.includes(u.value));
                    let _count = _valueArray.length;
                    _valueArray = _valueArray.slice(0, 5);
                    if ((_count - 5) > 0)
                        _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                    applied.push({
                        isHtml: true,
                        header: 'Groups',
                        value: _valueArray
                    });
                }
                else if (!(_appliedFilters.callQueueFilter == null || _appliedFilters.callQueueFilter == "")) {
                    _appliedFilters.callQueueFilter = _appliedFilters.callQueueFilter.split(',').map(Number);
                    let _valueArray = props.userFiltersData.callQueueFilter.items.filter(u => _appliedFilters.callQueueFilter.includes(u.value));
                    let _count = _valueArray.length;
                    _valueArray = _valueArray.slice(0, 5);
                    if ((_count - 5) > 0)
                        _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                    applied.push({
                        isHtml: true,
                        header: 'Call Queues',
                        value: _valueArray
                    });
                }
                else if (!(_appliedFilters.agentFilter == null || _appliedFilters.agentFilter == "")) {
                    _appliedFilters.agentFilter = _appliedFilters.agentFilter.split(',').map(Number);
                    let _valueArray = props.userFiltersData.agentFilter.items.filter(u => _appliedFilters.agentFilter.includes(u.value));
                    let _count = _valueArray.length;
                    _valueArray = _valueArray.slice(0, 5);
                    if ((_count - 5) > 0)
                        _valueArray.push({ value: -10, header: '+' + (_count - 5) });
                    applied.push({
                        isHtml: true,
                        header: 'Agents',
                        value: _valueArray
                    });
                }
                if (widget_filterIds[_appliedFilters.widgetId]) {
                    if (widget_filterIds[_appliedFilters.widgetId].callQuality == 1 && !(_appliedFilters.callQualityFilter == null || _appliedFilters.callQualityFilter == "")) {
                        _appliedFilters.callQualityFilter = _appliedFilters.callQualityFilter.split(',').map(Number);
                        applied.push({
                            header: 'Call Quality',
                            value: props.userFiltersData.isCallQuality.items.filter(u => _appliedFilters.callQualityFilter.includes(u.value)).map(d => d.header).join(", ")
                        });
                    }

                    if (widget_filterIds[_appliedFilters.widgetId].modalities == 1 && !(_appliedFilters.modalitiesFilter == null || _appliedFilters.modalitiesFilter == "")) {
                        _appliedFilters.modalitiesFilter = _appliedFilters.modalitiesFilter.split(',').map(Number);
                        applied.push({
                            header: 'Modalities',
                            value: props.userFiltersData.isModalities.items.filter(u => _appliedFilters.modalitiesFilter.includes(u.value)).map(d => d.header).join(", ")
                        });
                    }
                    if (widget_filterIds[_appliedFilters.widgetId].reactions == 1 && !(_appliedFilters.reactionFilter == null || _appliedFilters.reactionFilter == "")) {
                        _appliedFilters.reactionFilter = _appliedFilters.reactionFilter.split(',').map(Number);
                        applied.push({
                            header: 'Reactions',
                            value: props.userFiltersData.isReaction.items.filter(u => _appliedFilters.reactionFilter.includes(u.value)).map(d => d.header).join(", ")
                        });
                    }

                    if (widget_filterIds[_appliedFilters.widgetId].sentiment == 1 && !(_appliedFilters.sentimentFilter == null || _appliedFilters.sentimentFilter == "")) {
                        _appliedFilters.sentimentFilter = _appliedFilters.sentimentFilter.split(',').map(Number);
                        applied.push({
                            header: 'Sentiments',
                            value: props.userFiltersData.isSentiment.items.filter(u => _appliedFilters.sentimentFilter.includes(u.value)).map(d => d.header).join(", ")
                        });
                    }
                }

            }
            setAppliedFilters(applied);
        }
        catch (e) {
            console.log(e);
        }
    }

    function getCardData() {
        var cardUrl = "";
        FiltersApiService.GetSharedCardData(props.card.cardURL).then((response) => {
            if (
                response === null ||
                response === "" || response.data === null ||
                (Array.isArray(response.data) && response.data.length === 0) ||
                (typeof response.data === "object" && response.data.categories === null)
            ) {
                setNoData(true);
                setIsLoaded(true);
            } else {
                setNoData(false);
                setData(response);
                setIsLoaded(true);
            }
            if (response != null && response.filters != null) {
                prepareInfoData(response.filters);
                setInfoViewAvailable(true)
            }
            else {
                setInfoViewAvailable(false)
                setInfoView(false)
            }
        });
    }

    useEffect(() => {
        getCardData();
    }, [props.card]);
    /*
        useEffect(()=>{
            clearInterval(cardTimer)
            getCardData();
            cardTimer = setInterval(function(){ 
                getCardData();
            }, 180000);
            return () => clearInterval(cardTimer);
        }, []);
    */
    useEffect(() => {
        if (global.localStorage) {
            var _layout = JSON.parse(localStorage.getItem('layout' + props.dashboardParam));
            if (_layout != null) {
                let _currentLayout = _layout[props.currentBreakpoint == 'xs' ? 'xxs' : props.currentBreakpoint];
                let _cardLayout = _currentLayout.find(c => c.i == props.card.cardID);
                setCardLayout({ w: _cardLayout.h == 2 ? 1 : Math.min(_cardLayout.w / 3, 3), h: _cardLayout.h });
            }
        }
    }, [props.currentBreakpoint]);

    const handleExitFullscreen = () => document.exitFullscreen();

    const handleInfoClick = (cardId) => {
        setInfoView(!isInfoView);
    }


    return (
        <div id={"cardParent_" + props.card.cardID} style={{ width: '100%', height: '100%' }} ref={maximizableElement}>
            <Card compact fluid id={"cardDownload_" + props.card.cardID}>
                <CardHeader>
                    <Flex
                        vAlign="center"
                        className="cardDesc"
                        space="between"
                        style={{ width: "100%" }}
                    >
                        <Text content={props.card.name} weight="bold" />
                        <Flex style={{ marginRight: "2px" }} className="cardActions">
                            {
                                isInfoViewAvailable &&
                                <Button
                                    disabled={operating || props.disabled}
                                    icon={isInfoView ? <TranscriptIcon /> : <InfoIcon />}
                                    iconOnly
                                    text
                                    title={isInfoView ? "Data View" : "Info View"}
                                    onClick={() => handleInfoClick(props.card.cardID)}
                                    style={{ whiteSpace: 'break-spaces' }}


                                />
                            }
                            {
                                errorMessage ? <></> :
                                    (props.currentBreakpoint == "lg" || props.currentBreakpoint == "md") ?
                                        isFullscreen ? (
                                            <Button
                                                icon={<CollapseIcon />}
                                                iconOnly
                                                text
                                                title="Exit Fullscreen"
                                                onClick={handleExitFullscreen}
                                            />
                                        ) : (
                                            <Button
                                                icon={<ExpandIcon />}
                                                iconOnly
                                                text
                                                title="Fullscreen"
                                                onClick={setIsFullscreen}
                                            />
                                        )
                                        : <></>
                            }
                        </Flex>
                    </Flex>
                </CardHeader>
                <CardBody>
                    {!isInfoView ?
                        isDemo ? (
                            {
                                0: <StandardGrid data={mockData} widgetId={props.card.widgetID} />,
                                2: <GroupedGrid data={mockData} widgetId={props.card.widgetID} />,
                                3: <MeetingSummaryView data={mockData} />,
                                4: <MeetingHabitsIndicator data={mockData} />,
                                5: <DonutChart data={mockData} />,
                                6: <ReactionsView data={mockData} />,
                                7: <BarChart data={mockData} />,
                                8: <DonutChart data={mockData} />,
                                9: <TopUsersGrid data={mockData} widgetId={props.card.widgetID} />,
                                10: <TeamSummaryView data={mockData} />,
                                11: <MeetingParticipation data={mockData} />,
                                12: <BasicSummary data={mockData} />,
                                13: <UserSummaryView data={mockData} />,
                                15: <UserComparisonView data={mockData} />,
                                16: <ComparisonView data={mockData} />,
                                18: <StackedBarChart data={mockData} widgetId={props.card.widgetID} />,
                                19: <PieChart data={mockData} />,
                                20: <CallQueueSummaryView data={mockData} />,
                                21: <StackedBarChartAndGroupedGrid data={mockData} widgetId={props.card.widgetID} />,
                                22: <TeamEngagementView data={mockData} widgetId={props.card.widgetID} />,
                                23: <ExpandableRowGrid data={mockData} widgetId={props.card.widgetID} />,
                                24: <OnetoOneOverview data={mockData} widgetId={props.card.widgetID} />,
                                25: <ManagementInsights data={mockData} />,
                                26: <ColumnChart data={mockData} widgetId={props.card.widgetID} />,
                                27: <DirectReportOverview data={mockData} widgetId={props.card.widgetID} />,
                                28: <OrganizationalChart data={mockData} widgetId={props.card.widgetID} />,
                                29: <GridFilteredByInsight data={mockData} widgetId={props.card.widgetID} />,
                                31: <LineChart data={data.data} />

                                // 20: <UserAnalytics data={data} />,
                            }[props.card.viewTypeID]
                        ) :
                            isLoaded ? (!noData ? (
                                {

                                    0: <StandardGrid data={data.data} widgetId={props.card.widgetID} />,
                                    2: <GroupedGrid data={data.data} widgetId={props.card.widgetID} />,
                                    3: <MeetingSummaryView data={data.data} />,
                                    4: <MeetingHabitsIndicator data={data.data} />,
                                    5: <DonutChart data={data.data} />,
                                    6: <ReactionsView data={data.data} />,
                                    7: <BarChart data={data.data} />,
                                    8: <DonutChart data={data.data} />,
                                    9: <TopUsersGrid data={data.data} widgetId={props.card.widgetID} />,
                                    10: <TeamSummaryView data={data.data} />,
                                    11: <MeetingParticipation data={data.data} />,
                                    12: <BasicSummary data={data.data} />,
                                    13: <UserSummaryView data={data.data} />,
                                    15: <UserComparisonView data={data.data} />,
                                    16: <ComparisonView data={data.data} />,
                                    18: <StackedBarChart data={data.data} />,
                                    19: <PieChart data={data.data} />,
                                    20: <CallQueueSummaryView data={data.data} />,
                                    21: <StackedBarChartAndGroupedGrid data={data.data} widgetId={props.card.widgetID} />,
                                    22: <TeamEngagementView data={data.data} widgetId={props.card.widgetID} />,
                                    23: <ExpandableRowGrid data={data.data} widgetId={props.card.widgetID} />,
                                    24: <OnetoOneOverview data={data.data} widgetId={props.card.widgetID} />,
                                    25: <ManagementInsights data={data.data} />,
                                    26: <ColumnChart data={data.data} widgetId={props.card.widgetID} />,
                                    27: <DirectReportOverview data={data.data} widgetId={props.card.widgetID} />,
                                    28: <OrganizationalChart data={data.data} widgetId={props.card.widgetID} />,
                                    29: <GridFilteredByInsight data={data.data} widgetId={props.card.widgetID} />,
                                    31: <LineChart data={data.data} />
                                    // 20: <UserAnalytics data={data.data} />,
                                }[props.card.viewTypeID]
                            ) : (
                                <Flex vAlign="center" fill hAlign="center">
                                    <Text size="large" content="No data to display." />
                                </Flex>
                            )) : (
                                <Loader style={{ height: "100%" }} />
                            )
                        :
                        <div style={{ padding: '8px' }}>
                            <Text content={MockData[props.card.widgetID].description} />
                            <div style={{ paddingLeft: '12px', marginTop: '8px', marginBottom: '4px' }}><Text content="Applied Filters" /></div>
                            <div className="card-filters" style={{ gridTemplateColumns: `repeat(${cardLayout.w}, 1fr)` }}>
                                {
                                    appliedFilters.map((d) => {
                                        return (
                                            <div className="card-filter-row">
                                                <div className="card-filter-header">
                                                    <Text weight="bold" content={d.header} />
                                                </div>
                                                <div>
                                                    {d.isHtml ?
                                                        d.value.map((dd) => {
                                                            return (
                                                                <Avatar
                                                                    image={dd.additionalContent}
                                                                    label={dd.value === -10 ? dd.header : ""}
                                                                    name={dd.value !== -10 ? dd.header : ""}
                                                                    size="large"
                                                                />
                                                            );
                                                        })
                                                        :
                                                        <Text className="card-filter-value" content={d.value} />
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    }
                </CardBody>
            </Card>
        </div>
    );
};

export { SharedCardWidget };
