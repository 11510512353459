import "./UserSummaryView.scss";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as React from "react";
import { orderBy } from "@progress/kendo-data-query";
import { EmojiIcon } from "@fluentui/react-northstar";

const UserSummaryView = ({ data }) => {
    const [sort, setSort] = React.useState([]);
    const [callstate, setCallstate] = React.useState(0);
    const NAfornocalls = "N/A";
    // const data = props.data
    return (
        <div className="usr-view" style={{ fontSize: "12px" }}>
            <div className="usr-vHdr-cst pt-2 mb-2">
                <div className="usr-vHdr-pro">
                    <div className="topUsrAreaPic">
                        <div className="topUsrAreaPic-i">
                            <div className="contact-list-icon">
                                <div className="contact-list-icon-txt">
                                    <div className="contact-list-icon-img">
                                        <img src={data.image} />
                                    </div>
                                    {data.image == "" ? data.initials : ""}
                                </div>
                            </div>
                        </div>
                        <div className="topUsrAreaPic-tx">
                            <div className="topUsrAreaPic-h">{data.userName}</div>
                            <div className="topUsrAreaPic-p">{data.designation}</div>
                            <div className="topUsrAreaPic-p">{data.email}</div>
                        </div>
                    </div>
                </div>
                <div className="usrHdrR">
                    <div className="usrHdr-topR float-right pl-3">
                        <div className="usr-vHdr-qt">
                            <div className="usr-vHdr-qtTx" >Network Quality</div>
                            <div className="usr-vHdr-qtVal">
                                {data.calls.total > 0 ?
                                    (<div className="progress" style={{ height: ".5rem" }}>
                                        <div className="progress-bar bg-success" style={{ width: `${data.callQuality.good}%` }} title={`good : ${data.callQuality.good}%`}>

                                        </div>
                                        <div className="progress-bar bg-warning border-left" style={{ width: `${data.callQuality.avg}%` }} title={`Average : ${data.callQuality.avg}%`}>

                                        </div>
                                        <div className="progress-bar bg-danger border-left" style={{ width: `${data.callQuality.poor}%` }} title={`Poor : ${data.callQuality.poor}%`}>

                                        </div>
                                    </div>) : (<div className="progress">
                                        <div className="progress-bar bg-secondary" style={{ width: "100%" }} title="Call records not found or not available.">
                                            N/A
                                        </div>
                                    </div>)}
                            </div>
                        </div>
                    </div>

                    <div className="usrHdr-topR float-right border-right pr-3">
                        <div className="d-flex flex-column">
                            <div className="usr-vHdr-qtTx pb-0">Sentiment</div>
                            <div className="d-flex ml-auto">
                                {/*<EmojiIcon className={`usr-smy-sentiment ${data.messages.avgSentiments}`} title={data.messages.avgSentiments} />*/}
                                {data.messages.avgSentiments === 'Positive' && data.messages.total > 0 && <i className="bi bi-transparent bi-emoji-smile-fill" style={{ fontSize: "20px", color: '#28a745' }} title={data.messages.avgSentiments}></i>}
                                {data.messages.avgSentiments === 'Negative' && data.messages.total > 0 && <i className="bi bi-transparent bi-emoji-frown-fill" style={{ fontSize: "20px", color: '#dc3545' }} title={data.messages.avgSentiments}></i>}
                                {data.messages.avgSentiments === 'Neutral' && data.messages.total > 0 && <i className="bi bi-transparent bi-emoji-neutral-fill" style={{ fontSize: "20px", color: '#ffc107' }} title={data.messages.avgSentiments}></i>}
                                {data.messages.total === 0 && "N/A"}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="usr-vDtl">
                <div className="row">
                    <div className="col-md-4 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk border h-100">
                            <div className="vDtl-t border-bottom">
                                <span>Calls</span>
                            </div>
                            <div className="vDtl-inr p-0">
                                <div class="row h-60 mb-3">
                                    <div class="col mt-3 pt-2">
                                        <div class="row align-items-center">
                                            <div class="col pr-0">

                                                <div class="col text-center pr-0 fsh1 fwc dc1">
                                                    {data.calls.total}
                                                </div>
                                            </div>
                                            <div class="col pl-0 text-right">
                                                <div class="col pl-0 text-nowrap">
                                                    <span class="text-cst-g60 fsh3">External Calls&nbsp;</span>
                                                    <span class="fwc fst3">{data.calls.externalPercent}</span>
                                                </div>
                                                <div class="col pl-0 text-nowrap">
                                                    <span class="text-cst-g60 fsh3">One-to-one Calls&nbsp;</span>
                                                    <span class="fwc fst3">{data.calls.peerCalls}</span>
                                                </div>
                                                <div class="col pl-0 text-nowrap">
                                                    <span class="text-cst-g60 fsh3">Total Talk Time&nbsp;</span>
                                                    <span class={data.calls.totalTalkTime.length < 9 ? "fwc fst3" : "fwc fst3 cursor-default"} title={data.calls.totalTalkTime}>{data.calls.totalTalkTime < 9 ? data.calls.totalTalkTime : data.calls.totalTalkTime.substring(0, 8) + ".."}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div class="row h-40">
                                    <div class="col">
                                        <div class="container">
                                            <div className="row d-flex justify-content-between" >
                                                <div class="col pr-2 vl-r">
                                                    <div class="row align-items-center justify-content-between">
                                                        <div class="col-auto fst2 mr-1">Audio</div>
                                                        <div class="col-auto fwc fsh2">
                                                            {data.calls.audio}
                                                        </div>
                                                    </div>
                                                    <div class="row justify-content-end">
                                                        <div class="col fwc fsh3">{data.calls.totalAudioTalkTime}</div>
                                                    </div>
                                                </div>
                                                <div class="col px-2">
                                                    <div class="row align-items-center justify-content-between">
                                                        <span class="col-auto fst2 mr-1">Video</span>
                                                        <div class="col-auto fwc fsh2">
                                                            {data.calls.video}
                                                        </div>
                                                    </div>
                                                    <div class="row d-flex justify-content-end">
                                                        <span class="col fwc fsh3">{data.calls.totalVideoTalkTime}</span>
                                                    </div>
                                                </div>
                                                <div class="col pl-2 vl-l">
                                                    <div class="row align-items-center justify-content-between">
                                                        <span class="col fst2 mr-1">Phone</span>
                                                        <div class="col fwc fsh2">
                                                            {data.calls.externalPhone}
                                                        </div>
                                                    </div>
                                                    <div class="row d-flex justify-content-end">
                                                        <span class="col fwc fsh3">{data.calls.totalExternalPhoneTalkTime}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div class="row h-40 mb-2">
                                    <div class="col">
                                        <div class="container">
                                            <div class="row d-flex justify-content-between">
                                                <div class="col pr-2 text-right vl-r">
                                                    <div className="fwc fsh2 ml-1">{data.calls.audio}</div>
                                                    <div class="fst2">
                                                        <span>Audio</span>
                                                    </div>
                                                    <div class={data.calls.totalAudioTalkTime.length < 9 ? "text-secondary fwc fsh3" : "text-secondary fwc fsh3 cursor-default"} title={data.calls.totalAudioTalkTime}>
                                                        {data.calls.totalAudioTalkTime.length < 9 ? data.calls.totalAudioTalkTime : data.calls.totalAudioTalkTime.substring(0, 8) + ".."}
                                                    </div>
                                                </div>
                                                <div class="col px-2 text-right">
                                                    <div className="fwc fsh2 ml-1">{data.calls.video}</div>
                                                    <div class="fst2 ">
                                                        <span>Video</span>
                                                    </div>
                                                    <div class={data.calls.totalVideoTalkTime.length < 9 ? "text-secondary fwc fsh3" : "text-secondary fwc fsh3 cursor-default"} title={data.calls.totalVideoTalkTime} >
                                                        {data.calls.totalVideoTalkTime.length < 9 ? data.calls.totalVideoTalkTime : data.calls.totalVideoTalkTime.substring(0, 8) + ".."}
                                                    </div>
                                                </div>
                                                <div class="col pl-2 text-right vl-l">
                                                    <div className="fwc fsh2 ml-1">{data.calls.externalPhone}</div>
                                                    <div class="fst2 ">
                                                        <span>Phone</span>
                                                    </div>
                                                    <div class={data.calls.totalExternalPhoneTalkTime.length < 9 ? "text-secondary fwc fsh3" : "text-secondary fwc fsh3 cursor-default"} title={data.calls.totalExternalPhoneTalkTime}>
                                                        {data.calls.totalExternalPhoneTalkTime.length < 9 ? data.calls.totalExternalPhoneTalkTime : data.calls.totalExternalPhoneTalkTime.substring(0, 8) + ".."}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="row h-50">*/}
                                {/*    <div className="col-md-12 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div>{data.calls.total}</div>*/}
                                {/*                        <div className="vDtlRowTrk-val">h:m:s {data.calls.totalTalkTime}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">h:m:s</div>*/}
                                {/*                    </div>*/}
                                {/*                    */}{/*<div className="vDtlRowTrkLi">*/}
                                {/*                    */}{/*    <div className="vDtlRowTrk-val">{data.calls.totalTalkTime}</div>*/}
                                {/*                    */}{/*    <div className="vDtlRowTrk-p">h:m:s</div>*/}
                                {/*                    */}{/*</div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row h-50">*/}
                                {/*    <div className="col-md-6 pr-0 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.calls.organised}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">Organised</div>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.calls.organisedTalkTime}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">Duration</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-md-6 pl-0 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.calls.participated}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">Participated</div>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.calls.participatedTalkTime}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">Duration</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk border h-100">
                            <div className="vDtl-t border-bottom">
                                <span>Messages</span>
                            </div>
                            <div className="vDtl-inr p-0">
                                <div class="row mb-3 h-60">
                                    <div class="col mt-3 pt-2">
                                        <div class="row align-items-center">
                                            <div class="col pr-0">
                                                <div class="col pr-0 text-center fsh1 fwc dc1">
                                                    {data.messages.total}
                                                </div>
                                            </div>
                                            <div class="col pl-0 text-right">
                                                <div class="col text-nowrap pl-0">
                                                    <span class="text-cst-g60 fsh3">Posts&nbsp;</span>
                                                    <span class="fwc fst3">{data.messages.sent}</span>
                                                </div>
                                                <div class="col text-nowrap pl-0">
                                                    <span class="text-cst-g60 fsh3">Group Chat&nbsp;</span>
                                                    <span class="fwc fst3">{data.messages.group}</span>
                                                </div>
                                                <div class="col text-nowrap pl-0">
                                                    <span class="text-cst-g60 fsh3">One-to-one Chat&nbsp;</span>
                                                    <span class="fwc fst3">{data.messages.peer}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row h-40 mb-2">
                                    <div class="col">
                                        <div class="container">
                                            <div class="row d-flex justify-content-between">
                                                <div class="col pr-2 text-right vl-r">
                                                    <div class=" fwc fsh2">
                                                        {data.messages.replied}
                                                    </div>
                                                    <div class="fst3">
                                                        Replies
                                                    </div>
                                                </div>
                                                <div class="col px-2 text-right">
                                                    <div class=" fwc fsh2">
                                                        {data.messages.mentions}
                                                    </div>
                                                    <div class="fst3">
                                                        Mentions
                                                    </div>
                                                </div>
                                                <div class="col pl-2 text-right vl-l">
                                                    <div class=" fwc fsh2">
                                                        {data.messages.externalMessages}
                                                    </div>
                                                    <div class=" fst3">
                                                        External
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="row h-50">*/}
                                {/*    <div className="col-md-6 pr-0 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.messages.sent}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">Sent</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-md-6 pl-0 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.messages.replied}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">Replied</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row h-50">*/}
                                {/*    <div className="col-md-6 pr-0 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.messages.mentions}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">Mentions</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*    <div className="col-md-6 pl-0 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.messages.reactions}</div>*/}
                                {/*                        <div className="vDtlRowTrk-p">Reactions</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk border h-100">
                            <div className="vDtl-t border-bottom d-flex align-items-center justify-content-between" style={{ paddingBottom: "8px", paddingTop: "8px" }}>
                                <span className="d-block lh-1" style={{ fontSize: ".8em", width: "76px" }}>Internal Collaboration</span>
                                <div class="">
                                    <a href="javascript:void(0);" title="Meetings/Calls" class="p-r-3 text-secondary" onClick={() => setCallstate(2)}>
                                        {callstate === 2 ? <i class="bi bi-transparent bi-telephone-fill text-primary"></i> : <i class="bi bi-telephone bi-transparent"></i>}
                                    </a>
                                    <a href="javascript:void(0);" title="Messages" class="text-secondary" onClick={() => setCallstate(1)} >
                                        {callstate === 1 ? <i class="bi bi-transparent bi-chat-left-text-fill text-primary p-l-6 vl-l" style={{ padding: '0 0 0 7px', margin: '0 0 0 7px' }}></i> : <i class="bi bi-transparent bi-chat-left-text p-l-6 vl-l" style={{ padding: '0 0 0 7px', margin: '0 0 0 7px' }}></i>}
                                    </a>
                                    <a href="javascript:void(0);" title="Overall Collaboration" class="text-secondary" onClick={() => setCallstate(0)} >
                                        {callstate === 0 ? <i class="bi bi-transparent bi-people-fill text-primary p-l-6 vl-l" style={{ padding: '0 0 0 7px', margin: '0 0 0 7px' }}></i> : <i class="bi bi-transparent bi-people-fill p-l-6 vl-l" style={{ padding: '0 0 0 7px', margin: '0 0 0 7px' }}></i>}
                                    </a>
                                </div>
                            </div>
                            <div className="vDtl-inr">
                                {data.collaborators.collabList !== null && callstate === 2 ?
                                    orderBy(data.collaborators.collabList, sort).map((collaborator) => {
                                        return (
                                            <div className="vDtl-inrRow-cst">
                                                <div className="vDtl-inrRow-p">{collaborator.userName}</div>
                                                <div className="vDtl-inrRow-val">{collaborator.totalTalkTime}</div>
                                            </div>
                                        )
                                    })
                                    : []}
                            </div>
                            <div className="vDtl-inr">
                                {data.collaborators.msgCollabList !== null && callstate === 1 ?
                                    orderBy(data.collaborators.msgCollabList, sort).map((collaborator) => {
                                        return (
                                            <div className="vDtl-inrRow-cst">
                                                <div className="vDtl-inrRow-p">{collaborator.topCollabName}</div>
                                                <div className="vDtl-inrRow-val">{collaborator.topCollabCount}</div>
                                            </div>
                                        )
                                    })
                                    : []}
                            </div>
                            <div className="vDtl-inr">
                                {data.collaborators.overallCollabList !== null && callstate === 0 ?
                                    orderBy(data.collaborators.overallCollabList, sort).map((collaborator) => {
                                        return (
                                            <div className="vDtl-inrRow-cst">
                                                <div className="vDtl-inrRow-p">{collaborator.topCollabName}</div>
                                                <div className="vDtl-inrRow-val">{collaborator.topCollabTime}</div>
                                            </div>
                                        )
                                    })
                                    : []}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-8 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk border h-100">
                            <div className="vDtl-t border-bottom d-flex align-items-center justify-content-between" style={{ paddingBottom: "7px" }}>
                                <span className="d-block lh-1" style={{ fontSize: ".8em", width: "58px" }}>Scheduled Meetings</span>
                                <div className="pointLbl">
                                    <div className="pointLblTrk d-flex align-items-end">
                                        <div className="pointLblTrk-h">{data.meetingOverview.totalStatusUnknown}</div>
                                        <div className="pointLblTrk-p fs-9">Non-Teams Meetings</div>
                                    </div>
                                    <div className="pointLblTrk d-flex align-items-end">
                                        <div className="pointLblTrk-h">{data.meetingOverview.totalNotAttended}</div>
                                        <div className="pointLblTrk-p fs-9">Not Attended</div>
                                    </div>
                                    <div className="pointLblTrk d-flex align-items-end">
                                        <div className="pointLblTrk-h">{data.meetingOverview.totalAttended}</div>
                                        <div className="pointLblTrk-p fs-9">Attended</div>
                                    </div>
                                    <div className="pointLblTrk d-flex align-items-end">
                                        <div className="pointLblTrk-h">{data.meetingOverview.totalMeetings}</div>
                                        <div className="pointLblTrk-p fs-9">Total</div>
                                    </div>
                                </div>
                            </div>
                            <div className="vDtl-inr">
                                <div className="vDtl-inrRow-cst">
                                    <div className="vDtl-inrRow-p">Time in Meetings</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalTimeInMeeting}</div>
                                </div>
                                <div className="vDtl-inrRow-cst">
                                    <div className="vDtl-inrRow-p">Time in Recurring Meetings</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalTimeMeetingRecurring}</div>
                                </div>
                                <div className="vDtl-inrRow-cst">
                                    <div className="vDtl-inrRow-p">Recurring Meetings Attended</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalRecurringMeetings}&nbsp;%</div>
                                </div>
                                <div className="vDtl-inrRow-cst">
                                    <div className="vDtl-inrRow-p">Back-to-Back Meetings Attended</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalBackToBackMeetings}&nbsp;%</div>
                                </div>
                                <div className="vDtl-inrRow-cst">
                                    <div className="vDtl-inrRow-p">Meeting Conflicts</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalOverlappingMeetings}</div>
                                </div>
                                <div className="vDtl-inrRow-cst">
                                    <div className="vDtl-inrRow-p">Late Attendance</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalMeetingsLate}</div>
                                </div>
                                <div className="vDtl-inrRow-cst">
                                    <div className="vDtl-inrRow-p">Meeting Duration Exceeded</div>
                                    <div className="vDtl-inrRow-val">{data.meetings.totalMeetingsOverran}&nbsp;%</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 mb-2 pr-1 pl-1">
                        <div className="usr-vDtlRowTrk border h-100">
                            <div className="vDtl-t border-bottom">
                                <span>{data.timePeriod}</span>
                            </div>
                            <div className="vDtl-inr calcH43 p-0">
                                <div class="row h-50">
                                    <div class="col-md-12 d-flex align-items-center justify-content-center">
                                        <div class="vDtlRow">
                                            <div class="vDtlRowTrk">
                                                <div class="vDtlRowTrkUL">
                                                    <div class="vDtlRowTrkLi">
                                                        <div class="vDtlRowTrk-val fsh2">{data.nextXTime.totalMeetings}</div>
                                                        <div class="vDtlRowTrk-p">Scheduled Meetings</div>
                                                    </div>
                                                    <div class="vDtlRowTrkLi">
                                                        <div class="vDtlRowTrk-val">{data.nextXTime.totalTimeInMeeting}</div>
                                                        <div class="vDtlRowTrk-p">Duration</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row h-50">
                                    <div class="col-md-6 pr-0 d-flex align-items-center justify-content-center">
                                        <div class="vDtlRow">
                                            <div class="vDtlRowTrk">
                                                <div class="vDtlRowTrkUL">
                                                    <div class="vDtlRowTrkLi">
                                                        <div class="vDtlRowTrk-val fsh2">{data.nextXTime.totalRecurringMeetingPer}&nbsp;%</div>
                                                        <div class="vDtlRowTrk-p">Recurring</div>
                                                    </div>
                                                    <div class="vDtlRowTrkLi">
                                                        <div class="vDtlRowTrk-val">{data.nextXTime.totalTimeMeetingRecurring}</div>
                                                        <div class="vDtlRowTrk-p">Duration</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 pl-0 d-flex align-items-center justify-content-center">
                                        <div class="vDtlRow">
                                            <div class="vDtlRowTrk">
                                                <div class="vDtlRowTrkUL">
                                                    <div class="vDtlRowTrkLi">
                                                        <div class="vDtlRowTrk-val fsh2">{data.nextXTime.totalOverlappingMeetings}&nbsp;%</div>
                                                        <div class="vDtlRowTrk-p">Meeting Conflicts</div>
                                                    </div>
                                                    {/* <div class="vDtlRowTrkLi">
                                                        <div class="vDtlRowTrk-val">{data.nextXTime.totalTimeMeetingOverlapping}</div>
                                                        <div class="vDtlRowTrk-p">Duration</div>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/*<div className="row h-50">*/}
                                {/*    <div className="col-md-12 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.nextXTime.totalMeetings}&nbsp;Meetings</div>*/}
                                {/*                       */}{/* <div className="vDtlRowTrk-p">Meetings</div>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.nextXTime.totalTimeInMeeting}&nbsp;Duration</div>*/}
                                {/*                     */}{/*   <div className="vDtlRowTrk-p">Duration</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*</div>*/}
                                {/*<div className="row h-50">*/}
                                {/*   <div className="col-md-12 pr-0 d-flex align-items-center justify-content-center">*/}
                                {/*        <div className="vDtlRow">*/}
                                {/*            <div className="vDtlRowTrk">*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.nextXTime.totalRecurringMeetings}&nbsp;Recurring</div>*/}
                                {/*                        */}{/*<div className="vDtlRowTrk-p">Recurring</div>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.nextXTime.totalTimeMeetingRecurring}&nbsp;Duration</div>*/}
                                {/*                       */}{/* <div className="vDtlRowTrk-p">Duration</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*                <div className="vDtlRowTrkUL">*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.nextXTime.totalRecurringMeetings}&nbsp;Recurring</div>*/}
                                {/*                        */}{/*<div className="vDtlRowTrk-p">Recurring</div>*/}
                                {/*                    </div>*/}
                                {/*                    <div className="vDtlRowTrkLi">*/}
                                {/*                        <div className="vDtlRowTrk-val">{data.nextXTime.totalTimeMeetingRecurring}&nbsp;Duration</div>*/}
                                {/*                        */}{/* <div className="vDtlRowTrk-p">Duration</div>*/}
                                {/*                    </div>*/}
                                {/*                </div>*/}
                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </div> */}
                                {/*    */}{/*<div className="col-md-6 p-0 d-flex align-items-center justify-content-center">*/}
                                {/*    */}{/*    <div className="vDtlRow">*/}
                                {/*    */}{/*        <div className="vDtlRowTrk">*/}
                                {/*    */}{/*            <div className="vDtlRowTrkUL">*/}
                                {/*    */}{/*                <div className="vDtlRowTrkLi">*/}
                                {/*    */}{/*                    <div className="vDtlRowTrk-val">{data.nextXTime.totalOverlappingMeetings}</div>*/}
                                {/*    */}{/*                    <div className="vDtlRowTrk-p">Overlapping</div>*/}
                                {/*    */}{/*                </div>*/}
                                {/*    */}{/*                <div className="vDtlRowTrkLi">*/}
                                {/*    */}{/*                    <div className="vDtlRowTrk-val">{data.nextXTime.totalTimeMeetingOverlapping}</div>*/}
                                {/*    */}{/*                    <div className="vDtlRowTrk-p">Duration</div>*/}
                                {/*    */}{/*                </div>*/}
                                {/*    */}{/*            </div>*/}
                                {/*    */}{/*        </div>*/}
                                {/*    */}{/*    </div>*/}
                                {/*    */}{/*</div>*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserSummaryView;
